import { PostStoryblok, PostsConfigurationStoryblok } from '@/types/types-storyblok';
import { StoryblokReactComponent } from '../componentsMap';
import { PostSummary } from './summary';
import { PostSubtitle } from './subtitle';
import Image from 'next/image';
import { getImageDimensions } from '../../utils/asset';
import { StoryblokComponent } from '../storyblokComponent';
import { makeArticleJsonLdFromStoryblokPost } from '@/json-ld/article';
import { storyContentIsOfType } from '../../utils/types';
import { withContext } from '@/json-ld/context';
import { storyblokEditable } from '@storyblok/js';
import { PostRelatedArticles } from './related';
import { makeJsonLdBreadcrumbs } from '@/json-ld/breadcrumbs';
import { ELBreadcrumb } from '@/app/_components/breadcrumbs';
import { getCmsStory } from '@/storyblok/client';
import { PostAuthor } from './author';
import { RelatedLinks } from '@/storyblok/components/post/relatedLinks';

export const StoryblokPost: StoryblokReactComponent<PostStoryblok> = async ({ component, story }) => {
  const postLayout = await getCmsStory<PostsConfigurationStoryblok>('layout/posts');

  const [category] = component.categories || [];
  if (category !== undefined && typeof category !== 'object') {
    throw new Error('Post category relation not resolved');
  }
  if (component.author === undefined || typeof component.author === 'string') {
    throw new Error('Author relation not loaded');
  }
  const breadcrumbsData = [
    { isHome: true, label: 'Home', href: '/' },
    { label: 'Guide', href: '/guide' },
    ...(category
      ? [
          {
            label: category.name,
            href: `/guide/categorie/${category.slug}`,
          },
        ]
      : []),
    { label: component.title },
  ];

  const adBanners = [...(postLayout?.content.globalBanners ?? []), ...(component.sidebar ?? [])].filter(banner => {
    if (!banner.excludeFromSlugs) {
      return true;
    }
    const slugs = new Set(banner.excludeFromSlugs.split('\n').map(v => v.trim()));
    return !slugs.has(story.slug);
  });

  return (
    <>
      {storyContentIsOfType(story, 'post') && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(withContext(makeArticleJsonLdFromStoryblokPost(story))) }}
        />
      )}
      <div className="w-screen overflow-x-hidden max-w-screen-2xl px-4 md:px-20 mx-auto pt-6">
        <ELBreadcrumb data={breadcrumbsData} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(withContext(makeJsonLdBreadcrumbs(breadcrumbsData))) }}
        />
      </div>
      <div
        className="flex flex-col gap-10 py-10 md:grid grid-cols-[calc(30%-1.25rem)_calc(70%-1.25rem)] md:px-20 max-w-screen-2xl mx-auto relative"
        {...storyblokEditable(component)}
      >
        <h1 className="mx-4 md:mx-0 text-center md:text-left text-3xl md:text-4xl leading-tight col-start-1 col-span-2 md:w-8/12">
          {component.title}
        </h1>
        {component.author && <PostSubtitle story={story} component={component} />}
        <div className="px-4 overflow-hidden w-full col-start-2">
          {component.image && (
            <Image
              className="w-full h-auto rounded-xl"
              src={component.image.filename}
              alt={component.image.alt ?? ''}
              {...getImageDimensions(component.image)}
            />
          )}
        </div>
        <div className="w-full flex flex-col items-stretch px-4 md:px-0 col-start-1 row-span-2 row-start-3 ">
          <div className="md:sticky md:top-[calc(var(--header-height)+var(--header-top-offset)+2rem)]">
            <PostSummary story={story} component={component} />
            <div className="flex lg:hidden flex-col gap-2">
              {adBanners
                .filter(blok => blok.mobilePosition === 'start')
                .map(blok => (
                  <StoryblokComponent key={blok._uid} component={blok} story={story} />
                ))}
            </div>
            <div className="hidden lg:flex flex-col gap-2">
              {adBanners.map(blok => (
                <StoryblokComponent key={blok._uid} component={blok} story={story} />
              ))}
            </div>
          </div>
        </div>
        <div className="col-start-2 flex flex-col gap-14">
          {component.body?.map(blok => <StoryblokComponent key={blok._uid} component={blok} story={story} />)}
          {component.author && <PostAuthor author={component.author} />}
        </div>
        <div className="flex lg:hidden w-full flex-col gap-2 px-4 md:px-0">
          {adBanners
            .filter(blok => blok.mobilePosition === 'end' || blok.mobilePosition === '')
            .map(blok => (
              <StoryblokComponent key={blok._uid} component={blok} story={story} />
            ))}
        </div>

        {category && storyContentIsOfType(story, 'post') && (
          <PostRelatedArticles insidePost story={story} categoryUuids={[category.uuid]} post={story} />
        )}
        {postLayout?.content.relatedLinks && postLayout.content.relatedLinks.length && (
          <RelatedLinks component={postLayout.content.relatedLinks.at(0)!} story={story} />
        )}
      </div>
    </>
  );
};
