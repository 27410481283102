import { PostsBanner } from '@/app/_components/blog/postsBanner';
import { getCmsStories } from '@/storyblok/client';
import { PostStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import clsx from 'clsx';
import { StoryblokStory } from 'storyblok-generate-ts';

export const PostRelatedArticles = async ({
  categoryUuids,
  post,
  title,
  subtitle,
  story,
  insidePost,
}: {
  title?: string;
  subtitle?: string;
  post?: StoryblokStory<PostStoryblok>;
  categoryUuids: string[];
  story: StoryblokStory<StoryblokComponent>;
  insidePost?: boolean;
}) => {
  const relatedArticles = await getCmsStories<PostStoryblok>({
    filter_query: {
      component: { in: 'post' },
      categories: { any_in_array: categoryUuids.join(',') },
    },
    excluding_slugs: post?.full_slug,
    per_page: 3,
    resolve_relations: ['post.categories'],
  });
  if (relatedArticles.data.stories.length === 0) return null;
  return (
    <section className={clsx(insidePost ? 'col-span-2' : 'px-4 md:px-20 xl:px-0 max-w-section xl:mx-auto')}>
      <PostsBanner
        story={story}
        title={title ?? 'Articoli correlati'}
        subtitle={subtitle ?? 'Scopri altri articoli per prenderti cura della tua salute...'}
        posts={relatedArticles.data.stories}
      />
    </section>
  );
};
