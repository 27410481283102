import { PostsBanner } from '@/app/_components/blog/postsBanner';
import { StoryblokReactComponent } from './componentsMap';
import { PostStoryblok, PostsBannerStoryblok } from '@/types/types-storyblok';
import { storyblokEditable } from '@storyblok/js';
import { getCmsStories } from '../client';
import { StoryblokStory } from 'storyblok-generate-ts';

export const StoryblokPostsBanner: StoryblokReactComponent<PostsBannerStoryblok> = async ({ component, story }) => {
  let posts: StoryblokStory<PostStoryblok>[];
  if (component.posts.some(post => typeof post === 'string')) {
    posts = await getCmsStories<PostStoryblok>({
      by_uuids: component.posts.map(post => (typeof post === 'string' ? post : post.uuid)).join(','),
      resolve_relations: ['post.categories'],
    }).then(data => data.data.stories);
  } else {
    posts = component.posts as StoryblokStory<PostStoryblok>[];
  }

  return (
    <section className="max-w-section xl:mx-auto">
      <PostsBanner story={story} posts={posts} title={component.title} subtitle={component.subtitle} {...storyblokEditable(component)} />
    </section>
  );
};
