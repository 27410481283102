import { PostStoryblok, StoryblokComponent } from '@/types/types-storyblok';
import { getImageDimensions } from '@/storyblok/utils/asset';
import Link from 'next/link';
import Image from 'next/image';
import { StoryblokStory } from 'storyblok-generate-ts';
import clsx from 'clsx';
import { HTMLAttributes } from 'react';
import { clientEnv } from '@/env/client';

export interface PostsBannerProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  subtitle?: string;
  posts: StoryblokStory<PostStoryblok>[];
  story: StoryblokStory<StoryblokComponent>;
}

export const PostsBanner = ({ title, subtitle, posts, ...rest }: PostsBannerProps) => {
  return (
    <div {...rest} className="flex flex-col gap-8 px-5 pt-10 pb-6 md:col-span-2">
      <div className="flex flex-row justify-between items-end">
        <div className="flex flex-col gap-2">
          <h2 className="font-semibold text-neutral-darker text-3xl">{title}</h2>
          {subtitle && <p className="text-text-body text-lg leading-[27px] font-normal">{subtitle}</p>}
        </div>
      </div>
      <ul className="flex flex-col md:grid md:grid-cols-3 md:gap-x-8">
        {posts.map((article, i) => (
          <Link
            href={clientEnv.NEXT_PUBLIC_ELTY_PUBLIC_URL + '/guide/' + article.slug}
            key={article.uuid}
            className={clsx('pb-4 mb-4 border-neutral-100 border-solid', i !== posts.length - 1 && 'border-b md:border-none')}
          >
            <li className="h-full flex flex-row md:flex-col gap-5 group items-center justify-start">
              <div className="relative">
                {article.content.image?.filename ? (
                  <Image
                    className="aspect-square md:aspect-[1.9] object-cover w-28 md:w-full rounded-xl shrink-0"
                    src={article.content.image?.filename}
                    alt={article.content.image.alt ?? ''}
                    {...getImageDimensions(article.content.image)}
                  />
                ) : (
                  <div className="w-28 aspect-square bg-text-body shrink-0 rounded-xl md:aspect-[1.9] md:w-full" />
                )}
                <div className="bg-black/40 opacity-0 group-hover:opacity-100 absolute top-0 left-0 w-full h-full z-10 transition-opacity duration-300 rounded-xl" />
              </div>
              <div className="flex flex-col gap-2 md:flex-grow w-full overflow-hidden">
                <div className="flex flex-row flex-wrap gap-1.5">
                  {article.content.categories?.map(category => (
                    <span
                      key={typeof category === 'string' ? category : category.uuid}
                      title={typeof category === 'string' ? category : category.name}
                      className="whitespace-nowrap truncate text-elty-green bg-brandGreen-50 py-1 px-2 rounded-lg font-normal text-xs"
                    >
                      {typeof category === 'string' ? category : category.name}
                    </span>
                  ))}
                </div>
                <span title={article.content.title} className="text-neutral-darker font-semibold leading-6 md:line-clamp-3 lg:line-clamp-2">
                  {article.content.title}
                </span>
                <div className="hidden md:block md:flex-grow" />
                {article.published_at && (
                  <time className="text-text-body text-sm block">
                    {new Date(article.published_at).toLocaleString('it', {
                      month: 'short',
                      day: 'numeric',
                      year: 'numeric',
                    })}
                  </time>
                )}
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </div>
  );
};
